<template>
    <div class="pdf-container h-100 w-100"></div>
</template>
<script setup lang="ts">
    import { onMounted } from 'vue';
    import { userSession } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject'

    export interface Props {
        fileName: string,
        primKey: string,
        viewName: string,
        id: number,
        readOnly: boolean
    };

    interface CustomStamps {
        title: string,
        stamp: Blob
    }

    const props = defineProps<Props>();
    const dsAnnotations = createAnnotationsDO();
    const dsCustomStamps = createCustomStampsDO();
    const toolbarItems = PSPDFKit.defaultToolbarItems;
    var pdfInstance = undefined;
    var customStamps: CustomStamps[] = [];
    //remove image import
    toolbarItems.splice(19,1);
    await dsAnnotations.load();
    onMounted(() => {
        dsCustomStamps.load().then(async () => {
            await Promise.all(
                dsCustomStamps.data.map(async (row) => {
                let blob = await getImage(row.PrimKey);
                customStamps.push(
                    {
                        title:row.Title, 
                        stamp: blob
                    });
            }))
            loadPSPDFKit();
        });
    })

    async function loadPSPDFKit(){
      PSPDFKit.unload(".pdf-container");
      PSPDFKit.load({
        // access the pdfFile from props
        document: getDownlodUrl(props.viewName,props.primKey,props.fileName),
        instantJSON: await loadAnnotationsFromDB(),
        container: ".pdf-container",
        customUI: getCustomUI(),
        toolbarItems: toolbarItems,
        readOnly: true
        //theme: 'DARK',
      }).then((instance) => {
            instance.setViewState(instance.viewState.set("readOnly", props.readOnly));
            pdfInstance = instance;
            //IMPORTING CUSTOM STAMPS
            defineStampTemplate(instance).then((result) => {
                instance.setStampAnnotationTemplates(result)
            })

            initEvents(instance);
      });
    }


    async function loadAnnotationsFromDB(){
        let instantJSON = {annotations: [],format:"https://pspdfkit.com/instant-json/v1",attachments:{}}
        await Promise.all(dsAnnotations.data.map(async (annotation) => {
            let json = JSON.parse(annotation.Annot);
            instantJSON.annotations.push(json);
            if(json.contentType == 'image/png'){
                instantJSON.attachments[json.imageAttachmentId] = { binary : await blobToBase64(customStamps.find(stamp => stamp.title == json.description).stamp), contentType: "image/png"}
            }
        }))
        return instantJSON;
    }

    function getAnnotations(){
        pdfInstance.getAnnotations(0).then(function (annotations) {
            console.log(annotations);
        })
    }

    (window as any).getAnnotations = getAnnotations;

    function getDownlodUrl(viewName:string,primKey:string,fileName:string){
        return `/nt/api/view-pdf/${viewName}/${primKey}/${fileName}`;
    }

    async function getImage(primKey){
        const response = await fetch(`/api/file/download/atbv_Arena_CustomStamps/`+primKey,
                {method: 'GET', headers: {"Content-Type": "image/png", 'X-NT-API': true}});

        if (response.status === 200) {
            const blob = await response.blob();
            return blob;
        } else {
            throw new Error("Could not get stamp image");
        }
    }

    async function createImageStamp(instance:any,stamp:any){
        const imageAttachmentId = await instance.createAttachment(stamp.stamp);
        return new PSPDFKit.Annotations.ImageAnnotation({
            pageIndex: 0,
            contentType: "image/png",
            imageAttachmentId,
            description: stamp.title,
            boundingBox: new PSPDFKit.Geometry.Rect({
                left: 0,
                top: 0,
                width: 150,
                height: 150,
            }),
        });
    }

    async function defineStampTemplate(instance:any){
        const stampTemplates = PSPDFKit.defaultStampAnnotationTemplates;
        await Promise.all(customStamps.map(async stamp => {
            stampTemplates.unshift(await createImageStamp(instance, stamp))
        }))
        //free memory
        customStamps = null;
        return stampTemplates;
    }

    function getCustomUI(){
        return {
            [PSPDFKit.UIElement.Sidebar]: {
            [PSPDFKit.SidebarMode.ANNOTATIONS]({ containerNode }) {
                containerNode.style.padding = "0.5rem";

                if (!containerNode.querySelector(".MyCustomSidebarComponentHeader")) {
                    const header = document.createElement("div");
                    header.classList.add("MyCustomSidebarComponentHeader");
                    containerNode.prepend(header);
                }

                return {
                node: containerNode,
                onRenderItem({ itemContainerNode, item: annotation }) {
                    const footerAuthor = itemContainerNode.querySelector(".PSPDFKit-Sidebar-Annotations-Footer");
                    footerAuthor.textContent = `Creator: ${annotation.creatorName ?? userSession.name}`;
                }
                };
            }
            }
        }
    }

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(',')[1]; // Extract base64 portion from data URL
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    function initEvents(instance:any){
        instance.addEventListener("annotations.create", (createdAnnotations) => {
            createdAnnotations._tail.array.map(annot => {
                let annotJSON = PSPDFKit.Annotations.toSerializableObject(annot);
                annotJSON['creatorName'] = userSession.name;
                debugger;
                dsAnnotations.createNew({
                    Document_ID: props.id,
                    AnnotID: annotJSON.id,
                    Annot: JSON.stringify(annotJSON),
                    Page: annotJSON.pageIndex,
                    PSPDFKit: 1,
                    Type: annotJSON.type
                })
                
            })
        });
        instance.addEventListener("annotations.update", (updatedAnnotations) => {
            updatedAnnotations._tail.array.map(annot => {
                let annotJSON = PSPDFKit.Annotations.toSerializableObject(annot);
                annotJSON['creatorName'] = userSession.name;
                dsAnnotations.setCurrentIndexByPrimKey(dsAnnotations.data.find(row => row.AnnotID == annotJSON.id).PrimKey)
                dsAnnotations.current.Annot = JSON.stringify(annotJSON);
                dsAnnotations.save();
            })
        });
        instance.addEventListener("annotations.delete", (deletedAnnotations) => {
            deletedAnnotations._tail.array.map(annot => {
                let annotJSON = PSPDFKit.Annotations.toSerializableObject(annot);
                dsAnnotations.deleteItem(dsAnnotations.data.find(row => row.AnnotID == annotJSON.id))
            })
        });
    }

    function createCustomStampsDO(){
        return getOrCreateDataObject({
            id: 'dsCustomStamps',
            viewName: 'atbv_Arena_CustomStamps',
            maxRecords: -1,
            whereClause: "",
            fields: [
                { name: "PrimKey", type: "string" },
                { name: "Title", type: "string" }
            ]
        })
    }
    
    function createAnnotationsDO(){
        return getOrCreateDataObject({
            id: 'dsAnnotations',
            viewName: 'aviw_Arena_DocumentsAnnotations',
            maxRecords: -1,
            uniqueTable: "atbv_Arena_DocumentsAnnotations",
            allowUpdate: true,
            allowInsert: true,
            allowDelete: true,
            whereClause: "PSPDFKit = 1 && Document_ID = " + props.id ,
            fields: [
                { name: "Annot", type: "string" },
                { name: "AnnotID", type: "string" }
            ]
        })
    }

    async function exportPdf(){
        let buffer = await pdfInstance.exportPDF();
        return new Blob([buffer]);
    }

    defineExpose({
        exportPdf
    })
</script>